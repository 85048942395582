<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="localChanges"
      :expanded.sync="expanded"
      class="roles-table custom-data-table custom-icons"
      item-key="name"
      fixed-header
      :height="height"
      show-expand
      dense
      :items-per-page="1000"
      hide-default-footer
      hide-default-header
    >
      <template #header="{ props }">
        <thead>
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.value"
              :style="{ width: `${header.width}px` }"
            >
              <div
                v-if="header.value === 'data-table-expand'"
                class="text-center"
              >
                {{ header.text }}
              </div>
              <div v-else-if="roles.some((role) => role.name === header.text)">
                <div class="d-flex align-center justify-center">
                  <span>{{ header.text }}</span>
                  <v-menu
                    v-if="
                      !isRolePermanent(header.id) &&
                      (canUseViewAs || canEditRoles)
                    "
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on">
                        mdi-dots-vertical
                      </v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item
                        v-if="!isRolePermanent(header.id)"
                        dense
                        @click="renameRole(header.id, header.text)"
                      >
                        <v-icon left color="primary" small class="mr-2"
                          >fa fa-edit</v-icon
                        >
                        <v-list-item-title>Rename Role</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="canUseViewAs"
                        dense
                        @click="viewAsRole(header.text)"
                      >
                        <v-icon left color="primary" small class="mr-2"
                          >fa fa-eye
                        </v-icon>
                        <v-list-item-title>View App As Role</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="!isRolePermanent(header.id)"
                        dense
                        @click="deleteRole(header.id)"
                      >
                        <v-icon left color="primary" small class="mr-2"
                          >fa fa-trash</v-icon
                        >
                        <v-list-item-title>Delete Role</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
            </th>
          </tr>
        </thead>
      </template>

      <template #item="{ item, expand }">
        <tr class="group-header custom-table-row">
          <td class="expandable-title">
            <div class="expandable-title">
              <v-icon
                v-if="item.permissions.length"
                class="mr-3"
                @click="handleRowExpand(item, expand)"
              >
                {{
                  expandedRowNames.includes(item.name)
                    ? "fa fa-chevron-up"
                    : "fa fa-chevron-down"
                }}
              </v-icon>
              <b>{{ item.name }}</b>
              <v-spacer></v-spacer>
              <v-tooltip v-if="item.description" bottom>
                <template #activator="{ on, attrs }">
                  <v-icon class="ml-2" small v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                {{ item.description }}
              </v-tooltip>
              <v-chip v-if="item.permissions.length" class="px-2" x-small>
                {{ item.permissions.length }}
              </v-chip>
              <v-menu v-if="canEditRoles" bottom>
                <template #activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on"
                    >mdi-dots-vertical</v-icon
                  >
                </template>
                <v-list dense>
                  <v-list-item dense @click="editResource(item)">
                    <v-icon left color="primary" small class="mr-2"
                      >fa fa-edit</v-icon
                    >
                    <v-list-item-title>Edit Resource</v-list-item-title>
                  </v-list-item>
                  <v-list-item dense @click="deleteResource(item)">
                    <v-icon left color="primary" small class="mr-2"
                      >fa fa-trash</v-icon
                    >
                    <v-list-item-title>Delete Resource</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
          <td
            v-for="(header, index) in headers.slice(1)"
            :key="header.value"
            :style="{ width: header.width }"
          >
            <v-checkbox
              v-if="index < headers.slice(1).length - 1"
              :disabled="!canUpdatePermissions || isRolePermanent(header.id)"
              :indeterminate="isIndeterminate(item, header.text)"
              :input-value="isAllChecked(item, header.text)"
              class="mt-0 custom-container custom-icons"
              hide-details
              small
              @change="toggleGroupPermissions(item, header.text, $event)"
            />
          </td>
        </tr>
      </template>

      <template #expanded-item="{ item }">
        <tr
          v-for="permission in item.permissions"
          :key="permission.exid"
          class="custom-table-row"
        >
          <td class="pl-8" style="width: 200px">{{ permission.name }}</td>
          <td
            v-for="(header, index) in headers.slice(1)"
            :key="header.value"
            :style="{ width: header.width }"
          >
            <v-checkbox
              v-if="index < headers.slice(1).length - 1"
              v-model="permission.permissions[header.text]"
              :disabled="!canUpdatePermissions || isRolePermanent(header.id)"
              class="mt-0 custom-container custom-icons"
              hide-details
              small
            />
          </td>
        </tr>
      </template>

      <template #footer>
        <div class="table-footer pa-3">
          <v-btn small class="mr-2" color="primary" @click="expandAll">
            Expand all
          </v-btn>
          <v-btn small color="primary" @click="collapseAll">
            Collapse all
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            text
            :disabled="!hasChanges"
            @click="undoChanges"
          >
            Undo Changes
          </v-btn>
          <v-btn
            small
            color="primary"
            :disabled="!hasChanges"
            @click="saveChanges"
          >
            Save Changes
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <AddRoleDialog
      :id="editedRoleId"
      v-model="isAddRoleDialogOpen"
      :name="editedRoleName"
      @role-added="handleRoleRenamed"
    />
    <AddResourceDialog
      v-model="isAddResourceDialogOpen"
      :resource="editedResource"
      @resource-added="handleResourceAdded"
    />
  </div>
</template>

<script>
import { AuthzApi } from "@evercam/shared/api/authzApi"
import AddRoleDialog from "@/components/authz/AddRoleDialog.vue"
import AddResourceDialog from "@/components/authz/AddResourceDialog.vue"
import { PERMANENT_ROLES } from "@/constants/permissions"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  components: {
    AddResourceDialog,
    AddRoleDialog,
  },
  props: {
    roles: {
      type: Array,
      required: true,
    },
    resources: {
      type: Array,
      required: true,
    },
    height: {
      type: [Number, String],
      required: true,
    },
    canUpdatePermissions: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localChanges: [],
      expanded: [],
      headers: [
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
          width: 230,
        },
        {
          text: "",
          value: "",
          sortable: false,
          align: "center",
        },
      ],
      isAddRoleDialogOpen: false,
      editedRoleName: "",
      editedRoleId: null,
      isAddResourceDialogOpen: false,
      editedResource: null,
      PERMANENT_ROLES,
      PERMISSIONS,
    }
  },
  computed: {
    expandedRowNames() {
      return this.expanded.map((i) => i.name)
    },
    hasChanges() {
      return (
        JSON.stringify(this.localChanges) !== JSON.stringify(this.resources)
      )
    },
    canUseViewAs() {
      return this.$permissions.user.is(PERMANENT_ROLES.SUPER_ADMIN)
    },
    canEditRoles() {
      return this.$permissions.user.can(
        PERMISSIONS.ACCESS_CONTROL.UPDATE_ROLE_PERMISSIONS
      )
    },
  },
  watch: {
    resources: {
      immediate: true,
      handler(newResources) {
        this.localChanges = structuredClone(newResources)
      },
    },
    roles: {
      immediate: true,
      handler(newRoles) {
        const rolesHeaders = newRoles.map((role) => ({
          text: role.name,
          value: role.name,
          id: role.id,
          align: "center",
          sortable: false,
          width: 120,
        }))

        this.headers = [
          {
            text: "",
            value: "data-table-expand",
            sortable: false,
            width: 230,
          },
          ...rolesHeaders,
          {
            text: "",
            value: "",
            sortable: false,
            align: "center",
          },
        ]
      },
    },
  },
  methods: {
    isAllChecked(resource, role) {
      return resource.permissions.every(
        (permission) => permission.permissions[role]
      )
    },
    expandAll() {
      this.expanded = this.localChanges
    },
    collapseAll() {
      this.expanded = []
    },
    isIndeterminate(resource, role) {
      const allChecked = this.isAllChecked(resource, role)
      const noneChecked = resource.permissions.every(
        (permission) => !permission.permissions[role]
      )

      return !allChecked && !noneChecked
    },
    toggleGroupPermissions(resource, role, checked) {
      const updatedResource = this.localChanges.find(
        (r) => r.name === resource.name
      )
      if (updatedResource) {
        updatedResource.permissions.forEach((permission) => {
          this.$set(permission.permissions, role, checked) // Ensure reactivity
        })
      }
    },
    handleRowExpand(item, expand) {
      expand(!this.expanded.some((i) => i.name === item.name))
    },
    undoChanges() {
      this.localChanges = structuredClone(this.resources)
    },
    saveChanges() {
      const updatedRoles = this.roles.map((role) => ({
        ...role,
        permissions: this.localChanges.flatMap((resource) =>
          resource.permissions
            .filter((permission) => permission.permissions[role.name])
            .map((permission) => permission.exid)
        ),
      }))
      this.$emit("save-changes", updatedRoles)
    },
    async deleteRole(roleId) {
      if (
        await this.$confirmDialog.open({
          title: "Are you sure ?",
          message: "This will delete the role and all its permissions.",
        })
      ) {
        try {
          await AuthzApi.roles.deleteRole(roleId)
        } catch (error) {
          console.error("Error deleting role:", error)
        } finally {
          this.$emit("rename-or-delete")
        }
      }
    },
    renameRole(roleId, roleName) {
      this.editedRoleName = roleName
      this.editedRoleId = roleId
      this.isAddRoleDialogOpen = true
    },
    handleRoleRenamed() {
      this.editedRoleName = ""
      this.editedRoleId = null
      this.$emit("rename-or-delete")
    },
    async deleteResource(resource) {
      if (
        await this.$confirmDialog.open({
          title: "Are you sure ?",
          message: "This will delete the resource and all its permissions.",
        })
      ) {
        try {
          await AuthzApi.scopes.delete(resource.id)
          this.$notifications.success("Resource deleted successfully")
        } catch (error) {
          this.$notifications.error("Failed to delete resource")
          console.error("Error deleting resource:", error)
        } finally {
          this.$emit("rename-or-delete")
        }
      }
    },
    editResource(resource) {
      this.editedResource = {
        ...resource,
        permissions: resource.permissions.map((perm) => perm.exid),
      }
      this.isAddResourceDialogOpen = true
    },
    handleResourceAdded() {
      this.editedResource = null
      this.$emit("rename-or-delete")
    },
    viewAsRole(roleName) {
      const role = this.roles.find((r) => r.name === roleName)
      const rolePermissions = this.localChanges.reduce((acc, resource) => {
        const permissionsForRole = resource.permissions
          .filter((permission) => permission.permissions[roleName])
          .map((permission) => permission.exid)

        return [...acc, ...permissionsForRole]
      }, [])
      const encodedPermissions = btoa(
        JSON.stringify({ role, permissions: rolePermissions })
      )

      const baseUrl = `${window.location.origin}`
      const url = `${baseUrl}?viewAsRole=${encodedPermissions}`

      window.open(url, "_blank")
    },
    isRolePermanent(roleId) {
      return Object.values(PERMANENT_ROLES).includes(roleId)
    },
  },
}
</script>

<style scoped>
.expandable-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.roles-table th .d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.roles-table {
  i {
    font-size: 1.3rem !important;
  }

  table-layout: fixed;

  th > span {
    white-space: nowrap;
  }

  .v-input__slot {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.table-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
.custom-table-row:hover {
  background-color: rgba(201, 231, 255, 0.74) !important;
  color: rgba(0, 107, 191, 0.96) !important;
}
</style>
